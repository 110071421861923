import { useFeature } from '@src/featureFlags';
import { ContainerTemplate, ContainerType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { getContainerCardType } from '@src/utils';

import { PreviewPlaceholder } from '../PreviewPlaceholder';
import { ContainerPreviewData } from '../types';
import { snakeToKebabCase } from '../utils';
import { DefaultList } from './DefaultList';
import { EventList } from './EventList';
import { EventListLegacy } from './EventListLegacy';
import { ResiMediaLibrary } from './ResiMediaLibrary';

const UNSUPPORTED_CONTAINER_TYPES = [
	ContainerType.All,
	ContainerType.PushCategory,
	ContainerType.SelectCampus,
	ContainerType.Messages,
];

export function ContainerPreview({
	container,
	isRootContainer = false,
}: {
	container: ContainerPreviewData;
	isRootContainer?: boolean;
}) {
	const { translate } = useTranslation('appDesign');
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const template = getContainerTemplate(container.template, isRootContainer);

	if (getContainerCardType(container.type) === 'ccb') {
		return <PreviewPlaceholder placeholderText={translate('preview.ccbContainerPlaceholder')} />;
	}

	if (UNSUPPORTED_CONTAINER_TYPES.includes(container.type)) {
		return <PreviewPlaceholder placeholderText={translate('preview.unsupportedContainerPlaceholder')} />;
	}

	if (container.type === ContainerType.Favorites) {
		return <PreviewPlaceholder placeholderText={translate('preview.favoriteContainerPlaceholder')} />;
	}

	if (container.type === ContainerType.Events && template === 'list-90dp-section') {
		if (!isInAppCalendarEnabled) {
			return <EventListLegacy container={container} template={template} />;
		}
		return <EventList container={container} />;
	}

	if (container.type === ContainerType.Resi) {
		return <ResiMediaLibrary container={container} />;
	}

	return <DefaultList container={container} template={template} />;
}

function getContainerTemplate(template: ContainerTemplate, isRootContainer: boolean) {
	if (isRootContainer) return 'list-44dp-icon';

	switch (template) {
		case ContainerTemplate.List_90DpSectionLegacy:
			return 'list-90dp-section';

		case ContainerTemplate.Unknown:
			return 'list-44dp';

		default:
			return snakeToKebabCase(template);
	}
}
