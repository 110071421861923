import { useState, useEffect, useCallback, useRef, PropsWithChildren } from 'react';

import { useLocation } from 'react-router-dom';

import PreviewNavigation, {
	makeDisconnectedNavigationRoutes,
	MORE_TAB_ROUTE,
	PreviewContentContainer,
} from '@pushpay/app-components/dist/app/containers/navigation/Preview';
import ThemeContext from '@pushpay/app-components/dist/app/contexts/ThemeContext';
import { getDarkTheme, getDefaultTheme } from '@pushpay/app-components/dist/app/themes/themes';
import { Container } from '@pushpay/app-components/dist/app/types/content';
import { NavigationSettings, Route } from '@pushpay/app-components/dist/app/types/navigation';
import Features from '@pushpay/app-components/dist/config';

import {
	AppPreviewNavigationContextProvider,
	AppPreviewNavigationData,
	ContentStateContext,
	ContentType,
	useContentStateContext,
} from '@src/context';
import { useFeature } from '@src/featureFlags';
import {
	ApplicationSettingKey,
	FeatureKey,
	GetContainerChildrenQuery,
	GetContainerSettingsQuery,
	GetItemQuery,
} from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';
import { PreviewScaleContainer } from '@src/pages/appDesign/components/PreviewScaleContainer';
import { useRootContent } from '@src/pages/root';
import { useAppDesignNavigate } from '@src/pages/shell';
import { generatePath, ROUTE_PATHS } from '@src/router';
import { useGetAppSettings, useGetQueryAndMutationVars } from '@src/shared/hooks';

import { PreviewContent } from './PreviewContent';
import { PreviewLoading } from './PreviewLoading';
import { RootContainer } from './types';

const NAVIGATION_UPDATE_DELAY = 200; // ms

type AppPreviewProps = {
	isDarkMode: boolean;
};

function AppPreviewContainer({ children, isDarkMode }: PropsWithChildren<AppPreviewProps>) {
	const { organizationKey, campusId } = useGetQueryAndMutationVars();
	const { key: locationKey } = useLocation();
	const [routeKey, setRouteKey] = useState(locationKey);
	const navigationDataRef = useRef<AppPreviewNavigationData>({});

	const isInAppCalendarEnabled = useFeature('InAppCalendar');
	Features.IN_APP_CALENDAR = () => isInAppCalendarEnabled; // This line should be removed with mobile FF removal

	const {
		currentApp: application,
		dhs: { enabled: isDhsEnabled },
		getAppFeature,
	} = useMyApp();
	const appDesignNavigate = useAppDesignNavigate(organizationKey);

	const isProfileEnabled = getAppFeature(FeatureKey.AppProfile)?.enabled || false;

	const settings = useGetAppSettings();

	const { data: rootContentData } = useRootContent();

	const updatePreviewNavigation = useCallback(() => {
		setRouteKey(crypto.randomUUID());
	}, []);

	const updateNavigationData = useCallback(
		(data: AppPreviewNavigationData) => {
			navigationDataRef.current = {
				...navigationDataRef.current,
				...data,
			};
			updatePreviewNavigation();
		},
		[updatePreviewNavigation]
	);

	useEffect(() => {
		// add small delay before updating the app preview to match the new route
		// to get around the issue with React Navigation's NavigationContainer sometimes failing to update
		const timeoutId = setTimeout(() => {
			updatePreviewNavigation();
		}, NAVIGATION_UPDATE_DELAY);

		return () => {
			clearTimeout(timeoutId);
			navigationDataRef.current = {};
		};
	}, [locationKey, updatePreviewNavigation, updateNavigationData]);

	// sync root container changes with app preview's header and tab navigation
	useEffect(() => {
		updatePreviewNavigation();
	}, [rootContentData, updatePreviewNavigation]);

	const { loadingPreview, error, ...contentState } = useContentStateContext();

	if (loadingPreview || error) {
		return <PreviewLoading error={error} />;
	}

	const currentNavData = navigationDataRef.current;
	const isCurrentContainer = (containerId: string) =>
		currentNavData.containerId && currentNavData.containerId === containerId;

	if (settings && rootContentData?.organization?.application?.rootContainer) {
		const { rootContainer } = rootContentData.organization.application;

		const roots = (rootContainer.children.nodes as RootContainer[])
			.filter(child => {
				if (child.hidden) {
					return false;
				}

				if (isCurrentContainer(child.id) && currentNavData.containerCampusId) {
					return currentNavData.containerCampusId === campusId;
				}

				return !child.container.campus || child.container.campus?.id === campusId;
			})
			.map<Container>(child => ({
				uuid: child.id,
				name:
					isCurrentContainer(child.id) && currentNavData.screenTitle
						? currentNavData.screenTitle
						: child.name,
				icon:
					isCurrentContainer(child.id) && currentNavData.icon ? currentNavData.icon : (child.icon as string),
				position: child.position,
				parent: '',
				type: 'root',
				onTabPress: () => {
					const containerPath = generatePath(ROUTE_PATHS.CONTAINER_CHILDREN, { containerId: child.id });
					appDesignNavigate(containerPath);
				},
				nav_action:
					child.__typename === 'ChildContainer' ? child.container.navigationAction?.toLowerCase() : '',
			}));

		const { appSettings, getSettingValueByKey } = settings;

		const navigationSettings = {
			dynamicTabIcon: getSettingValueByKey(ApplicationSettingKey.UiDhsTabIcon),
			dynamicTabLabel: getSettingValueByKey(ApplicationSettingKey.UiDhsTabLabel),
			defaultNavBarBackgroundColor: getSettingValueByKey(ApplicationSettingKey.UiTitlebarColor),
			navBarBackgroundColor: getSettingValueByKey(ApplicationSettingKey.UiTitlebarColor),
			navBarTintColor: getSettingValueByKey(ApplicationSettingKey.UiTitlebarFontColor),
			selectCampusTitle: getSettingValueByKey(ApplicationSettingKey.UiSelectCampusTitle),
			tabBarActiveTintColor: getSettingValueByKey(ApplicationSettingKey.UiColorTabTint),
			tabBarInactiveTintColor: getSettingValueByKey(ApplicationSettingKey.UiColorTabInactive),
			tabBarBackgroundColor: getSettingValueByKey(ApplicationSettingKey.UiColorTab),
			homeHeaderImageUrl: application.images.brandedHeader,
			darkHomeHeaderImageUrl: application.images.brandedHeaderDark,
		} as NavigationSettings;

		const theme = isDarkMode
			? getDarkTheme(navigationSettings, appSettings)
			: getDefaultTheme(navigationSettings, appSettings);

		const themedNavigationSettings = {
			...navigationSettings,
			navBarBackgroundColor: theme.themeColors.background.navigationBar,
			navBarTintColor: theme.themeColors.text.navigationBar,
			tabBarInactiveTintColor: theme.themeColors.image.tabBarInactive,
			tabBarBackgroundColor: theme.themeColors.background.tabBar,
			tabBarActiveTintColor: theme.themeColors.image.tabBarActive,
		};

		const titleBarTheme = getSettingValueByKey(ApplicationSettingKey.UiTitlebarTheme);

		const { routes, currentRouteName, nestedRoute } = getAppPreviewRoutes({
			currentRoute: getCurrentRoute(contentState),
			roots,
			isDhsEnabled,
			navigationSettings,
			onDhsTabPress: () => appDesignNavigate(ROUTE_PATHS.DHS),
			navigationData: currentNavData,
		});

		return (
			<ThemeContext.Provider value={theme}>
				<PreviewScaleContainer appSettings={appSettings} titleBarTheme={titleBarTheme}>
					<AppPreviewNavigationContextProvider
						navigationData={currentNavData}
						updateNavigationData={updateNavigationData}
					>
						<PreviewNavigation
							currentRouteName={currentRouteName}
							hiddenRoute={nestedRoute}
							isProfileEnabled={isProfileEnabled}
							navigationSettings={themedNavigationSettings}
							routeKey={routeKey}
							routes={routes}
							shouldShowBookmark={currentNavData.shouldShowBookmark ?? false}
						/>
						{children}
					</AppPreviewNavigationContextProvider>
				</PreviewScaleContainer>
			</ThemeContext.Provider>
		);
	}

	return null;
}

function AppPreview(props: AppPreviewProps) {
	return (
		<AppPreviewContainer {...props}>
			<PreviewContentContainer style={{ position: 'absolute', width: '100%', height: '636px', top: '110px' }}>
				<PreviewContent />
			</PreviewContentContainer>
		</AppPreviewContainer>
	);
}

function getCurrentRoute({
	contentData,
	type: contentType,
}: Pick<ContentStateContext, 'contentData' | 'type'>): Route | undefined {
	let container;
	let entity;
	let parent;
	let navAction;

	if (contentType === ContentType.CONTAINER_CHILDREN || contentType === ContentType.CONTAINER_SETTING) {
		container = (contentData as GetContainerChildrenQuery | GetContainerSettingsQuery).organization?.application
			?.container;
		entity = container;
		// if parent container's parent is null then parent container is a root container (invisible to users) so we shouldn't set the container's parent
		parent = container?.parentContainer?.parentContainer ? container?.parentContainer?.id : '';
		navAction = container?.navigationAction?.toLowerCase();
	} else if (contentType === ContentType.ITEM) {
		container = (contentData as GetItemQuery).organization?.application?.container;
		entity = container?.item;
		parent = container?.id;
		navAction = '';
	}

	if (!container || !entity) {
		return undefined;
	}

	return {
		key: entity.id,
		routeName: container.id,
		container: {
			name: entity.name,
			uuid: container.id,
			nav_action: navAction,
			parent,
		} as any,
	};
}

function getAppPreviewRoutes({
	currentRoute,
	roots,
	isDhsEnabled,
	navigationSettings,
	onDhsTabPress,
	navigationData,
}: {
	currentRoute?: Route;
	roots: Container[];
	isDhsEnabled: boolean;
	navigationSettings: NavigationSettings;
	onDhsTabPress: () => void;
	navigationData: AppPreviewNavigationData;
}): { routes: Route[]; currentRouteName?: string; nestedRoute?: Route } {
	const routes = makeDisconnectedNavigationRoutes({
		roots,
		isDhsEnabled,
		dynamicTabIcon: navigationSettings.dynamicTabIcon,
		dynamicTabLabel: navigationSettings.dynamicTabLabel,
		onDhsTabPress,
	});

	if (currentRoute === undefined) {
		return { routes };
	}

	const rootRoute = routes.find(route => route.routeName === currentRoute.routeName);
	const moreTabRoute = routes.find(route => route.routeName === MORE_TAB_ROUTE);
	const isMoreTabRoute =
		moreTabRoute?.container?.roots?.find(container => container.uuid === currentRoute.routeName) !== undefined;

	let currentRouteName: string | undefined;
	let nestedRoute: Route | undefined;

	const containerName = navigationData.screenTitle ?? currentRoute.container.name;
	const navAction = navigationData.navAction ?? currentRoute.container.nav_action;

	if (rootRoute) {
		rootRoute.container.parent = rootRoute.routeName !== currentRoute.key ? rootRoute.routeName : '';
		rootRoute.container.screenTitle = containerName;
		rootRoute.container.nav_action = navAction;
		currentRouteName = currentRoute.routeName;
	} else if (moreTabRoute && isMoreTabRoute) {
		moreTabRoute.container.parent = 'More';
		moreTabRoute.container.screenTitle = containerName;
		moreTabRoute.container.nav_action = navAction;
		currentRouteName = MORE_TAB_ROUTE;
	} else {
		nestedRoute = currentRoute;
		nestedRoute.container.screenTitle = containerName;
		nestedRoute.container.nav_action = navAction;
		nestedRoute.container.parent = currentRoute.container.parent;
	}

	return {
		routes,
		currentRouteName,
		nestedRoute,
	};
}

export { AppPreview };
