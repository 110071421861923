import { useEffect, useState } from 'react';

import moment from 'moment';

import {
	RELATED_CONTENT_MIN_ITEMS,
	RELATED_CONTENT_MAX_ITEMS,
	RelatedContentTypes,
} from '@pushpay/app-components/dist/app/constants/item_related';
import { MediaStatus } from '@pushpay/app-components/dist/app/constants/media';
import { getImageSource } from '@pushpay/app-components/dist/app/helpers/item_image';
import {
	getVideoThumbUrl,
	isBookmarkable,
	isItemAudio,
} from '@pushpay/app-components/dist/app/helpers/item_properties';
import {
	getRelatedItemsType,
	getSortedFutureEvents,
	getStartTimeFromEventItem,
} from '@pushpay/app-components/dist/app/helpers/item_related';
import ItemScreen from '@pushpay/app-components/dist/app/screens/Item/ItemScreen';
import { AudioPlayerState } from '@pushpay/app-components/dist/app/types/audio_player';
import {
	ItemProperty,
	ItemRelated,
	ItemTemplate,
	PropertyUserData,
	ScreenItem,
} from '@pushpay/app-components/dist/app/types/content';
import { ContainerEntity } from '@pushpay/app-components/dist/app/types/dao';
import { getDynamicFieldsItems, FormState } from '@pushpay/forms';

import { getBaseProperty } from '@src/components/properties';
import { useAppPreviewContext, useAppPreviewNavigationUpdateContext } from '@src/context';
import { useFeature } from '@src/featureFlags';
import { ApplicationSettingKey, ItemType, SaveItemSettingsSchema } from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';
import { propertyInputTypeObj } from '@src/pages/itemSettings/utils';
import { underscoreToDot } from '@src/utils';

import { Container } from '../types';
import { getImageMap, noop, orderBy, snakeToKebabCase } from '../utils';

export type ItemPreviewProps = {
	container: Container;
	input: FormState<SaveItemSettingsSchema>['input'];
};

type MediaStatusType = typeof MediaStatus;
type ChildItem = Extract<Container['children']['nodes'][number], { __typename?: 'ChildItem' }>;
type Item = ChildItem['item'];

export function ItemPreview({ container, input }: ItemPreviewProps) {
	const { currentApp: application } = useMyApp();
	const { updateNavigationData } = useAppPreviewNavigationUpdateContext();
	const { appSettings, screenWidth } = useAppPreviewContext();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const primaryColor = appSettings.get(underscoreToDot(ApplicationSettingKey.UiColorPrimary));
	const itemId = input.itemId.value || '';

	const subtitle =
		getSubtitle(input.properties, container.subtitle ?? '') || input.cardDefinition.genericContent.subtitle.value;
	const applicationImage = application.images.homeScreenPlaceholder;
	const relatedItems = createRelatedItems(
		{ container, sourceItemId: itemId, applicationImage },
		isInAppCalendarEnabled
	);
	const screenItem = useScreenItem({ itemId, container, itemInput: input, subtitle, relatedItems });
	const itemName = screenItem.name;
	const userData = createUserData();
	const audioPlayer = createAudioPlayerState(screenItem.properties);
	const shouldShowBookmark = isBookmarkable(screenItem.properties);

	useEffect(() => {
		updateNavigationData?.({ screenTitle: itemName, shouldShowBookmark });
	}, [updateNavigationData, itemName, shouldShowBookmark]);

	return (
		<ItemScreen
			actionLookup={noop}
			analyticsSaveEvent={noop}
			audio_player={audioPlayer}
			dispatch={noop}
			formatWebviewUrl={noop}
			images={screenItem.images}
			isMiniPlayer={false}
			item={screenItem}
			itemTestID={itemName}
			name={itemName}
			navigateToGivingLink={noop}
			open={noop}
			openExternalApp={noop}
			openImageModal={noop}
			placeholderImageUrl=""
			playAudio={noop}
			playVideo={noop}
			primaryColor={primaryColor}
			propertyToClipboard={noop}
			settings={appSettings}
			subtitle={subtitle}
			template={screenItem.template}
			userData={userData}
			width={screenWidth}
		/>
	);
}

function useScreenItem({
	itemId,
	container,
	itemInput,
	subtitle,
	relatedItems,
}: {
	itemId: string;
	container: Container;
	itemInput: ItemPreviewProps['input'];
	subtitle: string;
	relatedItems: ItemRelated[];
}): ScreenItem {
	const [videoThumbUrl, setVideoThumbUrl] = useState<string | null>();
	const itemProperties = getItemInputProperties(container, itemInput);
	const actions = orderBy(
		itemProperties.filter(property => property.action_bar),
		['action_bar'],
		['asc']
	);
	const itemImages = JSON.stringify([
		getImageMap({
			square: container.item?.image?.urls?.square,
			original: container.item?.image?.urls?.original,
			panorama: container.item?.image?.urls?.panorama,
			landscape: container.item?.image?.urls?.landscape,
			dynamicHomeScreen: container.item?.image?.urls?.dynamicHomeScreen,
			unprocessedImageUrl: container.item?.image?.urls?.unprocessedImageUrl,
		}),
	]);
	const itemTemplate = snakeToKebabCase(itemInput.template.value ?? '') as ItemTemplate;
	const relatedContentType = getRelatedItemsType({ type: container.type.toLowerCase() } as any, relatedItems);
	const filteredRelatedItems =
		relatedContentType !== RelatedContentTypes.AUDIO
			? relatedItems.filter(item => item.uuid !== itemId)
			: relatedItems;
	const isAudio = isItemAudio(itemProperties);
	const parent = getParent(container);

	useEffect(() => {
		async function getVideoThumb() {
			const url = await getVideoThumbUrl(itemProperties);
			setVideoThumbUrl(url);
		}
		getVideoThumb();
	}, [itemProperties]);

	return {
		actions,
		alloy_id: 0, // not used
		feed_guid: null, // not used
		fetched: true,
		icon: itemInput.icon.value,
		image: null, // not used
		images: itemImages,
		isAudio,
		isFetchFailed: false, // not used
		isFetching: false,
		last_touched: '', // not used
		name: itemInput.name.value,
		parent,
		properties: itemProperties,
		relatedItems: filteredRelatedItems,
		relatedContentType,
		subtitle,
		template: itemTemplate,
		title: '', // not used
		type: '', // not used
		uuid: itemId,
		v1_id: null, // not used
		videoThumbUrl,
	};
}

function getItemInputProperties(container: Container, itemInput: ItemPreviewProps['input']): ItemProperty[] {
	const properties = getDynamicFieldsItems(itemInput.properties);

	const getExistingPropertyData = (id: string) => container.item?.properties.find(property => property.id === id);

	return transformProperties(
		properties.map(property => {
			const [propertyType, propertyObj] = Object.entries(property)[0];
			const { baseProperty } = propertyObj;
			const action = baseProperty.action.click.value;
			const existingProperty = getExistingPropertyData(baseProperty.id.value || '');

			const convertedProperty: ItemProperty = {
				header: baseProperty.header.value,
				position: baseProperty.position.value ?? 0,
				hide: baseProperty.isHidden.value ? 1 : 0,
				item: itemInput.itemId.value ?? '',
				action_bar_dup: baseProperty.actionBar && !baseProperty.actionBar.hideOriginalProperty.value ? 1 : 0,
				uuid: baseProperty.id.value ?? '',
				bos: JSON.stringify(getItemInputBos(property, existingProperty)),
				type: getPropertyType(propertyType),
				action_bar: baseProperty.actionBar ? baseProperty.actionBar.position.value : null,
				actions: action ? JSON.stringify({ click: action }) : null,
				icon: baseProperty.icon.value,
			};

			return convertedProperty;
		})
	);
}

function getItemProperties(item: Item): ItemProperty[] {
	return transformProperties(
		item.properties.map<ItemProperty>(property => ({
			header: property.header ?? '',
			position: property.position,
			hide: property.hidden ? 1 : 0,
			item: item.id,
			action_bar_dup: property.actionBar && property.actionBar.hideOriginalProperty ? 1 : 0,
			uuid: property.id,
			bos: JSON.stringify(getItemBos(property)),
			type: property.type,
			action_bar: property.actionBar ? property.actionBar.position : null,
			actions: property.action ? JSON.stringify({ click: property.action.click }) : null,
			icon: property.icon ?? '',
		}))
	);
}

function transformProperties(properties: ItemProperty[]): ItemProperty[] {
	return orderBy(
		properties.map(property => ({
			...property,
			type: property.type.toLowerCase(),
		})),
		['position'],
		['asc']
	);
}

function getPropertyType(type: string): string {
	return Object.entries(propertyInputTypeObj).find(([_, val]) => val === type)?.[0] ?? '';
}

function getParent({ image, relatedContent }: Container): ContainerEntity {
	const parentImages = getImageMap(image?.urls);

	return {
		related_content_on: relatedContent.isEnabled ? 1 : 0,
		related_content_title: relatedContent.title ?? '',
		images: parentImages ? JSON.stringify([parentImages]) : null,
	} as ContainerEntity;
}

function createRelatedItems(
	{
		container,
		sourceItemId,
		applicationImage,
	}: {
		container: Container;
		sourceItemId: string;
		applicationImage: any;
	},
	isInAppCalendarEnabled: boolean
): ItemRelated[] {
	const parentImages = getImageMap(container.image?.urls);
	const filteredItems = container.children.nodes
		.map(child => (child.__typename === 'ChildItem' ? child : null))
		.filter(Boolean) as ChildItem[];

	if (filteredItems.length && filteredItems.length >= RELATED_CONTENT_MIN_ITEMS) {
		const numItems = RELATED_CONTENT_MAX_ITEMS + 1;

		const completeItems = filteredItems
			.sort((a, b) => {
				const dateA = a.item.updatedDate || a.item.createdDate;
				const dateB = b.item.updatedDate || b.item.createdDate;
				return dateA && dateB ? dateB.diff(dateA) : 0; // descendng order sort
			})
			.map(({ item, name }) => {
				const itemImages = getImageMap(item.image?.urls);
				const itemProperties = getItemProperties(item);
				const itemSubtitle =
					item.cardDefinition?.__typename === 'BaseCardDefinition'
						? item.cardDefinition.content?.subtitle
						: null;

				return {
					uuid: item.id,
					name,
					properties: itemProperties,
					image: getImageSource({
						...(itemImages && { images: [itemImages] }),
						...(parentImages && { parentImages: [parentImages] }),
						placeholderImageUrl: applicationImage,
						isAudio: isItemAudio(itemProperties),
					}),
					subtitle: itemSubtitle,
					type: item.type.toLowerCase(),
				};
			}) as ItemRelated[];

		const isAllEvents = completeItems.every(item => item.type === ItemType.Event.toLowerCase());

		let futureEventList: ItemRelated[] = [];
		if (isAllEvents) {
			futureEventList = isInAppCalendarEnabled
				? getFutureEvents(completeItems, sourceItemId)
				: getFutureEventsLegacy(completeItems, sourceItemId);
		}

		const sortedItemList = isAllEvents ? futureEventList : completeItems;

		return sortedItemList.length > numItems ? sortedItemList.slice(0, numItems) : sortedItemList;
	}

	return [];
}

function getFutureEvents(items: ItemRelated[], selectedItemUuid: string): ItemRelated[] {
	const selectedItem = items.find(item => item.uuid === selectedItemUuid);
	if (!selectedItem) return [];

	const eventStartTimeOfSelectedItem = moment(getStartTimeFromEventItem(selectedItem)).unix();

	const filteredEvents = items.filter(
		item => moment(getStartTimeFromEventItem(item)).unix() >= eventStartTimeOfSelectedItem
	);
	const futureEvents = getSortedFutureEvents(filteredEvents);

	return futureEvents.length >= RELATED_CONTENT_MIN_ITEMS ? futureEvents : [];
}

function getFutureEventsLegacy(items: ItemRelated[], selectedItemUuid: string): ItemRelated[] {
	const itemsSortedByDate = getSortedFutureEvents(items);
	const selectedItemIndex = itemsSortedByDate.findIndex(item => item.uuid === selectedItemUuid);
	return itemsSortedByDate.slice(selectedItemIndex + 1);
}

function createAudioPlayerState(properties: ScreenItem['properties']): AudioPlayerState {
	return {
		playingStatuses: properties.reduce<Record<string, MediaStatusType[keyof MediaStatusType]>>(
			(acc, { type, uuid }) => {
				if (type === 'audio') {
					acc[uuid] = MediaStatus.READY;
				}
				return acc;
			},
			{}
		),
		nowPlaying: null,
		currentUuid: null,
	} as AudioPlayerState;
}

function createUserData(): PropertyUserData {
	return {
		favoritesData: {},
		blankifyItemsUserData: {
			getAllByItemUuid: () => Promise.resolve([]),
			getAllByKeyAndItemUuid: () => Promise.resolve([]),
		},
		userNotes: {
			create: noop,
			get: () => Promise.resolve([]),
			update: noop,
		},
	} as unknown as PropertyUserData;
}

function getSubtitle(propertiesInput: ItemPreviewProps['input']['properties'], containerSubtitle: string): string {
	const properties = getDynamicFieldsItems(propertiesInput);

	const matchingProperty = properties
		.filter(propertiesObj => getBaseProperty(propertiesObj).header.value === containerSubtitle)
		.sort((a, b) => {
			const aPosition = getBaseProperty(a).position.value ?? 0;
			const bPosition = getBaseProperty(b).position.value ?? 0;
			return aPosition - bPosition;
		})[0];

	if (matchingProperty) {
		const {
			appLinkPropertyInput,
			defaultPropertyInput,
			smsPropertyInput,
			textHtmlPropertyInput,
			textPropertyInput,
			websitePropertyInput,
		} = matchingProperty;

		if (appLinkPropertyInput) {
			return appLinkPropertyInput.label.value;
		}
		if (defaultPropertyInput) {
			return defaultPropertyInput.data.value;
		}
		if (smsPropertyInput) {
			return smsPropertyInput.messageLabel.value;
		}
		if (textHtmlPropertyInput) {
			return textHtmlPropertyInput.text.value;
		}
		if (textPropertyInput) {
			return textPropertyInput.textDescription.value;
		}
		if (websitePropertyInput) {
			return websitePropertyInput.label.value;
		}
	}

	return '';
}

function getItemInputBos(
	property: ItemPreviewProps['input']['properties'][number],
	existingProperty?: NonNullable<Container['item']>['properties'][number]
): Record<string, unknown> {
	const {
		addressPropertyInput,
		appLinkPropertyInput,
		audioPropertyInput,
		blankifyPropertyInput,
		callToActionPropertyInput,
		carouselPropertyInput,
		defaultPropertyInput,
		emailPropertyInput,
		facetimePropertyInput,
		givePropertyInput,
		keyMetricsPropertyInput,
		phonePropertyInput,
		sharePropertyInput,
		smsPropertyInput,
		textHtmlPropertyInput,
		textPropertyInput,
		timeframePropertyInput,
		userNotePropertyInput,
		videoPropertyInput,
		websitePropertyInput,
	} = property;

	if (addressPropertyInput) {
		return {
			street: addressPropertyInput.street.value,
			city: addressPropertyInput.city.value,
			state: addressPropertyInput.state.value,
			zip: addressPropertyInput.zip.value,
			latitude: addressPropertyInput.latitude.value,
			longitude: addressPropertyInput.longitude.value,
		};
	}
	if (appLinkPropertyInput) {
		return {
			data: appLinkPropertyInput.label.value,
			ios_app_url: appLinkPropertyInput.iosAppUrl.value,
			ios_default_url: appLinkPropertyInput.iosDefaultUrl.value,
			android_app_url: appLinkPropertyInput.androidAppUrl.value,
			android_default_url: appLinkPropertyInput.androidDefaultUrl.value,
		};
	}
	if (audioPropertyInput) {
		return {
			data: audioPropertyInput.label.value,
			url: audioPropertyInput.url.value,
		};
	}
	if (blankifyPropertyInput) {
		return {
			data: blankifyPropertyInput.content.value,
		};
	}
	if (callToActionPropertyInput) {
		return {
			data: callToActionPropertyInput.label.value,
			is_external: callToActionPropertyInput.isExternal.value,
			url: callToActionPropertyInput.url.value,
		};
	}
	if (carouselPropertyInput) {
		return {
			title: carouselPropertyInput.title.value,
			images: getDynamicFieldsItems(carouselPropertyInput.images as any)
				.map((inputImage: any) => {
					if (inputImage.existingImageInput && existingProperty?.__typename === 'CarouselProperty') {
						const matchingImage = existingProperty.images.find(
							existingImage => existingImage.id === inputImage.existingImageInput.id.value
						);
						return matchingImage?.urls?.square;
					}
					if (inputImage.newImageInput) {
						return inputImage.newImageInput.unprocessedImageUrl;
					}
					return null;
				})
				.filter(Boolean),
		};
	}
	if (defaultPropertyInput) {
		return {
			data: defaultPropertyInput.data.value,
		};
	}
	if (emailPropertyInput) {
		return {
			data: emailPropertyInput.emailAddress.value,
		};
	}
	if (facetimePropertyInput) {
		return {
			data: facetimePropertyInput.facetimeUrl.value,
		};
	}
	if (givePropertyInput) {
		return {
			giving_link: givePropertyInput.givingLink.value,
			is_external: true,
		};
	}
	if (keyMetricsPropertyInput) {
		const metrics = keyMetricsPropertyInput.metrics as any;
		return {
			metricsCount: keyMetricsPropertyInput.metricsCount.value,
			metrics: {
				col1: metrics[0]
					? {
							title: metrics[0].title.value,
							value: metrics[0].value.value,
					  }
					: null,
				col2: metrics[1]
					? {
							title: metrics[1].title.value,
							value: metrics[1].value.value,
					  }
					: null,
				col3: metrics[2]
					? {
							title: metrics[2].title.value,
							value: metrics[2].value.value,
					  }
					: null,
			},
		};
	}
	if (phonePropertyInput) {
		return {
			data: phonePropertyInput.phoneNumber.value,
		};
	}
	if (sharePropertyInput) {
		return {
			uuids: sharePropertyInput.sharedProperties.value,
		};
	}
	if (smsPropertyInput) {
		return {
			android_app_url: smsPropertyInput.androidAppUrl.value,
			android_default_url: smsPropertyInput.androidDefaultUrl.value,
			ios_app_url: smsPropertyInput.iosAppUrl.value,
			ios_default_url: smsPropertyInput.iosDefaultUrl.value,
			body: smsPropertyInput.messageBody.value,
			data: smsPropertyInput.messageLabel.value,
			phone_number: smsPropertyInput.phoneNumber.value,
		};
	}
	if (textPropertyInput) {
		return {
			data: textPropertyInput.textDescription.value,
		};
	}
	if (textHtmlPropertyInput) {
		return {
			text: textHtmlPropertyInput.text.value,
			data: textHtmlPropertyInput.textHtml.value,
		};
	}
	if (timeframePropertyInput) {
		return {
			start_time: timeframePropertyInput.startTime.value,
			end_time: timeframePropertyInput.endTime.value,
			all_day: timeframePropertyInput.allDay.value,
		};
	}
	if (userNotePropertyInput) {
		return {
			hint_text: userNotePropertyInput.hintText.value,
		};
	}
	if (videoPropertyInput) {
		return {
			data: videoPropertyInput.description.value,
			url: videoPropertyInput.url.value,
			is_embed: videoPropertyInput.isEmbed.value,
		};
	}
	if (websitePropertyInput) {
		return {
			data: websitePropertyInput.label.value,
			url: websitePropertyInput.url.value,
			is_external: websitePropertyInput.isExternal.value,
		};
	}

	return {};
}

function getItemBos(property: Item['properties'][number]): Record<string, unknown> {
	switch (property.__typename) {
		case 'AddressProperty':
			return {
				street: property.street,
				city: property.city,
				state: property.state,
				zip: property.zip,
				latitude: property.latitude,
				longitude: property.longitude,
			};
		case 'AppLinkProperty':
			return {
				data: property.label,
				ios_app_url: property.iosAppUrl,
				ios_default_url: property.iosDefaultUrl,
				android_app_url: property.androidAppUrl,
				android_default_url: property.androidDefaultUrl,
			};
		case 'AudioProperty':
			return {
				data: property.label,
				url: property.url,
			};
		case 'BlankifyProperty':
			return {
				data: property.content,
			};
		case 'CallToActionProperty':
			return {
				data: property.label,
				is_external: property.isExternal,
				url: property.url,
			};
		case 'CarouselProperty':
			return {
				title: property.title,
				images: property.images.map(image => image.urls?.square).filter(Boolean),
			};
		case 'DefaultProperty':
			return {
				data: property.data,
			};
		case 'GiveProperty':
			return {
				giving_link: property.givingLink,
				is_external: property.isExternal,
			};
		case 'KeyMetricsProperty':
			return {
				metricsCount: property.metricsCount,
				metrics: {
					col1: property.metrics[0]
						? {
								title: property.metrics[0].title,
								value: property.metrics[0].value,
						  }
						: null,
					col2: property.metrics[1]
						? {
								title: property.metrics[1].title,
								value: property.metrics[1].value,
						  }
						: null,
					col3: property.metrics[2]
						? {
								title: property.metrics[2].title,
								value: property.metrics[2].value,
						  }
						: null,
				},
			};
		case 'ShareProperty':
			return {
				uuids: property.sharedProperties.map(prop => prop.id),
			};
		case 'SmsProperty':
			return {
				android_app_url: property.androidAppUrl,
				android_default_url: property.androidDefaultUrl,
				ios_app_url: property.iosAppUrl,
				ios_default_url: property.iosDefaultUrl,
				body: property.messageBody,
				data: property.messageLabel,
				phone_number: property.phoneNumber,
			};
		case 'TextProperty':
			return {
				data: property.textDescription,
			};
		case 'TextHtmlProperty':
			return {
				text: property.text,
				data: property.textHtml,
			};
		case 'TimeframeProperty':
			return {
				start_time: property.timeframe.startTime,
				end_time: property.timeframe.endTime,
				all_day: property.timeframe.allDay,
			};
		case 'UserNoteProperty':
			return {
				hint_text: property.hintText,
			};
		case 'VideoProperty':
			return {
				data: property.description,
				url: property.url,
				is_embed: property.isEmbed,
			};
		case 'WebsiteProperty':
			return {
				data: property.label,
				url: property,
				is_external: property.isExternal,
			};
		default:
			return {};
	}
}
